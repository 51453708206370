import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ICompany, IDivision } from "@zonar-ui/auth/lib/models/company.model";
import { IPolicy } from "@zonar-ui/auth/lib/models/user-group-policy.model";
import { SettingInfo } from "@zonar-ui/sidenav/lib/models/setting.model";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IGroup } from "../models/IGroup";
import { Application } from "../models/core-api.models";
import {
	Asset,
	ConfigGet,
	EvirDivisionsGetInner,
	InspectionTypesResponse,
	InspectorsInner,
	LangDictGet,
	ZoneLayouts,
} from "../models/openAPIAliases";
import { LoggerService } from "./logger.service";
import { RequestService } from "./request/request.service";
import { ServiceUtilsService } from "./service-utils.service";

@Injectable({
	providedIn: "root",
})
export class GlobalApiCallsService {
	constructor(
		private requestService: RequestService,
		public logger: LoggerService,
		public serviceUtils: ServiceUtilsService,
	) {}

	public getCompany(companyId: string, endpoint: string): Observable<ICompany> {
		return this.requestService.get<ICompany>({
			url: `${endpoint}/companies/${encodeURIComponent(companyId)}`,
		});
	}

	public getCompanyTCUDevices(companyId: string, endpoint: string): Observable<HttpResponse<unknown>> {
		return this.requestService.get<HttpResponse<unknown>>({
			url: `${endpoint}/devices?companyId=${encodeURIComponent(companyId)}&deviceType=TCU`,
			httpOptions: { observe: "response" },
		});
	}

	public getApplication(applicationId: string, endpoint: string): Observable<Application> {
		return this.requestService.get<Application>({
			url: `${endpoint}/applications/${encodeURIComponent(applicationId)}`,
		});
	}

	public getInspectionTypes(companyId: string, endpoint: string): Observable<InspectionTypesResponse> {
		return this.requestService.get<InspectionTypesResponse>({
			url: `${endpoint}/inspection-types?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	public getAssetTypes(companyId: string, endpoint: string): Observable<ZoneLayouts> {
		return this.requestService.get<ZoneLayouts>({
			url: `${endpoint}/zone-layouts?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	public getAssetsLoader({
		companyId,
		endpointEVIR,
		hasAllDivisions,
		divisionIds,
	}: {
		companyId: string;
		endpointEVIR: string;
		hasAllDivisions: boolean;
		divisionIds: ReadonlyArray<string>;
	}) {
		const uri = `${endpointEVIR}/inspected-assets?${new URLSearchParams({
			companyId,
			...(hasAllDivisions ? {} : { allChildren: "true", divisionIds: divisionIds.join(",") }),
		})}`;
		return this.serviceUtils.getAllPages(uri, "inspected assets") as Observable<Asset[]>;
	}

	public getAssets(companyId: string, endpoint: string, hasAllDivisions: boolean, divisionIds: Array<string>) {
		const uri = `${endpoint}/inspected-assets?${new URLSearchParams({
			companyId,
			...(hasAllDivisions ? {} : { allChildren: "true", divisionIds: divisionIds.join(",") }),
		})}`;
		return this.serviceUtils.getAllPages(uri, "inspected assets") as Observable<Asset[]>;
	}

	public getInspectorsLoader({
		companyId,
		endpointEVIR,
		hasAllDivisions,
		divisionIds,
	}: {
		companyId: string;
		endpointEVIR: string;
		hasAllDivisions: boolean;
		divisionIds: ReadonlyArray<string>;
	}) {
		const uri = `${endpointEVIR}/inspectors?${new URLSearchParams({
			companyId,
			...(hasAllDivisions ? {} : { allChildren: "true", divisionIds: divisionIds.join(",") }),
		})}`;
		return this.serviceUtils.getAllPages(uri, "inspectors") as Observable<InspectorsInner[]>;
	}
	public getInspectors(companyId: string, endpoint: string, hasAllDivisions: boolean, divisionIds?: Array<string>) {
		const uri = `${endpoint}/inspectors?${new URLSearchParams({
			companyId,
			...(hasAllDivisions ? {} : { allChildren: "true", divisionIds: divisionIds.join(",") }),
		})}`;
		return this.serviceUtils.getAllPages(uri, "inspectors") as Observable<InspectorsInner[]>;
	}

	public getDivisionsLoader({
		endpointCompanies,
		divisionIds,
		hasAllDivisions,
		companyId,
	}: {
		divisionIds: ReadonlyArray<string>;
		hasAllDivisions: boolean;
		endpointCompanies: string;
		companyId: string;
	}) {
		return this.getCompanyDivision(companyId, endpointCompanies).pipe(
			map((divisions: Array<IDivision>) => {
				const dictionary = Object.fromEntries(divisions.map(({ id, ...data }) => [id, { id, ...data }]));

				return (
					hasAllDivisions
						? divisions
						: [
								...new Set(
									divisionIds.flatMap(id => [
										dictionary[id],
										...dictionary[id].children.map(childId => dictionary[childId]),
									]),
								),
						  ].filter(Boolean)
				).map(division => ({
					divisionId: division.id,
					divisionName: division.name,
					divisionType: division.type,
					active: division.status.toLocaleLowerCase() === "active",
				}));
			}),
		) as unknown as Observable<Array<EvirDivisionsGetInner>>;
	}

	public getDivision(companyId: string, divisionId: string, endpoint: string) {
		return this.requestService.get<IDivision>({
			url: `${endpoint}/companies/${encodeURIComponent(companyId)}/divisions/${encodeURIComponent(divisionId)}`,
		});
	}

	public getCompanyDivision(companyId: string, endpoint: string): Observable<Array<IDivision>> {
		return this.serviceUtils.getAllPages(
			`${endpoint}/companies/${encodeURIComponent(companyId)}/divisions?`,
			"company divisions",
			100,
			1,
			"per_page",
		);
	}

	// this is singular because in app.state.ts, we're taking the first in the array
	public getConfig(companyId: string, endpoint: string): Observable<ConfigGet[]> {
		return this.requestService.get<ConfigGet[]>({
			url: `${endpoint}/evir-configs?companyId=${encodeURIComponent(companyId)}&activeOnly=True`,
		});
	}

	public getPoliciesFromCompany(companyId: string, endpoint: string): Observable<Array<IPolicy>> {
		return this.requestService.get<Array<IPolicy>>({
			url: `${endpoint}/policies?companyId=${encodeURIComponent(companyId)}`,
		});
	}

	public getGroupsFromPolicy(policyId: string, endpoint: string): Observable<Array<IGroup>> {
		return this.requestService.get<Array<IGroup>>({
			url: `${endpoint}/groups?policyId=${encodeURIComponent(policyId)}`,
		});
	}

	public getLanguageDictionary(companyId: string, endpoint: string): Observable<LangDictGet> {
		const url = new URL(endpoint);
		url.pathname = `${url.pathname}/lang-dict`;
		url.searchParams.set("companyId", companyId);

		return this.requestService.get<LangDictGet>({
			url: url.toString(),
		});
	}

	/**
	 * Get active GTC company
	 * @param legacyAccountCode Legacy account code. For example, "mbl2020"
	 * @param endpoint Endpoint to make the call
	 * @returns Observable of ICompany object for the legacy account code
	 */
	public getActiveGtcCompany(legacyAccountCode: string, endpoint: string): Observable<Array<ICompany>> {
		return this.requestService.get<Array<ICompany>>({
			url: `${endpoint}/companies?legacyAccountCode=${encodeURIComponent(legacyAccountCode)}`,
		});
	}

	/**
	 * Get BigQuery Assets
	 *
	 * @param options - The options for the assets loader.
	 * @returns An observable that could contain the list of Asset
	 */
	getBigQueryAssetsLoader(options: { companyId: string; endpointEVIR: string }) {
		const { companyId, endpointEVIR } = options;
		return this.getBigQueryFilterValues(companyId, "assets", endpointEVIR);
	}

	/**
	 * Get BigQuery Divisions
	 * @param options - The options for the divisions loader.
	 * @returns An observable that could contain the list of EvirDivisionsGetInner
	 */
	getBigQueryDivisionsLoader(options: { companyId: string; endpointEVIR: string }) {
		const { companyId, endpointEVIR } = options;
		return this.getBigQueryFilterValues(companyId, "divisions", endpointEVIR);
	}

	/**
	 * Get BigQuery Inspectors
	 * @param  options - The options for the inspectors loader.
	 * @returns An observable that could contain the list of InspectorsInner
	 */
	getBigQueryInspectorsLoader(options: { companyId: string; endpointEVIR: string }) {
		const { companyId, endpointEVIR } = options;
		return this.getBigQueryFilterValues(companyId, "inspectors", endpointEVIR);
	}

	/**
	 * Get BigQuery filter values
	 * @param companyId company ID
	 * @param filterType BigQuery filter type: divisions, assets, or inspectors
	 * @param endpoint EVIR API endpoint
	 * @returns Observable of Divisions/Inspectors/Assets array
	 */
	private getBigQueryFilterValues(
		companyId: string,
		filterType: "divisions" | "assets" | "inspectors",
		endpoint: string,
	) {
		const uri = `${endpoint}/reports/filters?filter=${filterType}&companyId=${encodeURIComponent(companyId)}`;
		return this.serviceUtils.getAllPages(uri, filterType) as Observable<
			Array<EvirDivisionsGetInner | InspectorsInner | Asset>
		>;
	}

	/**
	 * Check if the company is using Group Policies
	 * @param companyId company ID
	 */
	public getCompanyLoginModeGroupPolicy(companyId: string): Observable<boolean> {
		const url = new URL(`${environment.environmentConstants.APP_ENDPOINT_CORE}/settings`);
		url.searchParams.set("ownerType", "COMPANY");
		url.searchParams.set("ownerId", companyId);
		url.searchParams.set("name", "GROUP_POLICY_STATUS");
		url.searchParams.set("status", "ACTIVE");
		return from(
			this.requestService
				.get<SettingInfo>({ url: url.toString() })
				.toPromise()
				.then(settings => {
					return settings[0]?.value === "ENABLED";
				}),
		);
	}
}
